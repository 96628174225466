import { createTheme } from '@mui/material'

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#ff5500',
    },
    success: {
      main: '#08B839',
    },
    secondary: {
      main: '#32abe3',
    },
    text: {
      primary: '#000E3E',
      secondary: '#4D5678',
      disabled: '#666768',
    },
    background: {
      default: '#FCFCFC',
    },
    divider: '#EDEEF2',
    error: {
      main: '#D4321C',
    },
    info: {
      main: '#1299C3',
    },
    warning: {
      main: '#4416c4',
    },
    grey: {
      50: '#F8F8F8',
      100: '#ECECEC',
      200: '#D6D6D6',
      300: '#C4C4C4',
      400: '#979797',
      500: '#5D6A7E',
    },
  },

  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#F4F4F4',
        },
      },
    },
  },

  typography: {
    fontFamily: 'MarkGEOCaps',
  },
})

const { pxToRem, fontFamily } = muiTheme.typography

muiTheme.typography = {
  ...muiTheme.typography,

  h1: {
    fontFamily: 'MarkGEOCaps',
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: 600,
    letterSpacing: '0.68px',
  },

  h2: {
    fontFamily: 'MarkGEOCaps',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    fontWeight: 600,
    letterSpacing: '0.52px',
  },

  h3: {
    fontFamily: 'MarkGEOCaps',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21),
    fontWeight: 600,
    letterSpacing: '0.45px',
  },

  h4: {
    fontFamily: 'MarkGEOCaps',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    fontWeight: 600,
    letterSpacing: '0.45px',
  },

  body1: {
    fontFamily,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(19),
    fontWeight: 400,
    letterSpacing: '0.52px',
  },

  body2: {
    fontFamily,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    fontWeight: 400,
    letterSpacing: '0.52px',
  },

  caption: {
    fontFamily,
    display: 'inline-block',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    letterSpacing: '0.52px',
  },

  button: {
    fontFamily: 'MarkGEOCaps',
  },
}

muiTheme.components = {
  ...muiTheme.components,
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'primary',

      disableElevation: true,
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          height: 48,
          boxShadow:
            '0px 1px 3px -1px rgba(0, 0, 0, 0.3), 0px 2px 5px -1px rgba(50, 50, 93, 0.25)',
          '&:hover': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.3), 0px 3px 6px -1px rgba(50, 50, 93, 0.25)',
          },
          '&:focus': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.3), 0px 3px 6px -1px rgba(50, 50, 93, 0.25)',
          },
          color: '#FFF',
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          height: 48,
          boxShadow:
            '0px 1px 3px -1px rgba(0, 0, 0, 0.15), 0px 2px 5px -1px rgba(50, 50, 93, 0.12)',
          '&:hover': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.15), 0px 3px 4px -1px rgba(50, 50, 93, 0.12)',
          },
          '&:focus': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.15), 0px 3px 4px -1px rgba(50, 50, 93, 0.12)',
          },
        },
      },
    ],
  },

  MuiInputBase: {
    defaultProps: {
      sx: { fontFamily: 'MarkGEOCaps' },
    },
  },

  MuiTab: {
    defaultProps: {
      sx: { fontFamily: 'MarkGEOCaps' },
    },
  },
  MuiTableHead: {
    defaultProps: {
      sx: { fontFamily: 'MarkGEOCaps' },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: muiTheme.palette.text.primary,
      },
    },
  },
}
