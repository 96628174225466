import { PermissionKey } from 'lib/permissions'
import { ComponentType } from 'react'
import lazy, { PreloadableComponent } from 'react-lazy-with-preload'

import { routes } from './paths'

export type LazyRouteProps = {
  title: string
  path: string
  component: PreloadableComponent<ComponentType<any>>
  exact?: boolean
}

export type Route = LazyRouteProps & { permissions?: Array<PermissionKey> }

export const authRoutesData: Array<Route> = [
  {
    title: 'Flats',
    path: routes.super,
    component: lazy(() => import('pages/super/flats')),
    permissions: ['canViewFlats'],
  },
  {
    title: 'Employees',
    path: routes.employees,
    component: lazy(() => import('pages/super/employees')),
    permissions: ['canViewEmployee'],
  },
  {
    title: 'Employee',
    path: routes.employee,
    component: lazy(() => import('pages/employee')),
    permissions: ['canViewOwnFlats'],
  },
  {
    title: 'User registration',
    path: routes.employeeUserRegistration,
    component: lazy(() => import('pages/employee/residentRegistration')),
    permissions: ['canViewUserRegistration'],
  },

  {
    title: 'Flat Profile',
    path: routes.flatProfile,
    component: lazy(() => import('pages/flatprofile')),
    // permissions: ['canViewUserRegistration'],
  },
  {
    title: 'Flat Edit',
    path: routes.flatEdit,
    component: lazy(() => import('pages/employee/flatEdit')),
    // permissions: ['canViewUserRegistration'],
  },
  {
    title: 'User Profile',
    path: routes.user,
    component: lazy(() => import('pages/user')),
    permissions: ['residentViewFlat'],
  },
  {
    title: 'Flat Profile',
    path: routes.chairman,
    component: lazy(() => import('pages/user')),
    permissions: ['chairmanViewFlat'],
  },
  {
    title: 'Message to all residents',
    path: routes.chairmanMsg,
    component: lazy(() => import('pages/chairman/msg')),
    permissions: ['chairmanMessage'],
  },
  {
    title: 'Costs',
    path: routes.costs,
    component: lazy(() => import('pages/employee/costs')),
    permissions: ['canViewCosts'],
  },
]

export const unauthRoutesData: Array<Route> = [
  {
    title: 'Home',
    path: routes.homePage,
    component: lazy(() => import('pages/homePage')),
  },
  {
    title: 'Authorization',
    path: routes.login,
    component: lazy(() => import('pages/login')),
  },
  {
    title: 'Registration',
    path: routes.registration,
    component: lazy(() => import('pages/registration')),
  },
]

export const allRoutesData = [...authRoutesData, ...unauthRoutesData]
