import * as t from 'io-ts'

export const TRefreshToken = t.type({
  access_token: t.string,
  refresh_token: t.string,
})

export const TAuth = t.type({
  access_token: t.string,
  refresh_token: t.string,
})

export type Auth = t.TypeOf<typeof TAuth>
