import './index.css'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { muiTheme } from 'app/theme'
import { CheckLoginProvider } from 'providers/login-provider'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import App from './App'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet
        defaultTitle="Elevators Portal"
        titleTemplate="%s · West Georgia"
      />
      <ThemeProvider theme={muiTheme}>
        <CssBaseline>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CheckLoginProvider>
                <BrowserRouter>
                  <Suspense fallback={null}>
                    <Toaster
                      position="bottom-right"
                      reverseOrder={false}
                      gutter={8}
                      toastOptions={{
                        duration: 1000,
                        error: {
                          duration: 3000,
                          position: 'top-center',
                        },
                        success: {
                          duration: 2500,
                          position: 'top-center',
                        },
                      }}
                    />
                    <App />
                  </Suspense>
                </BrowserRouter>
              </CheckLoginProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </CssBaseline>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
