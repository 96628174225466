/* eslint-disable react/no-children-prop */
import { routes } from 'app/paths'
import { authRoutesData, unauthRoutesData } from 'app/routes'
import { generateLazyRoutes } from 'lib/lazy-route'
import { usePermissions } from 'lib/permissions'
import { AuthType, useAuthContext } from 'providers/login-provider'
import { generatePath, Navigate, Route, Routes } from 'react-router-dom'
import { AppHeader } from 'shared/header'

function App() {
  const { auth } = useAuthContext()
  const permissions = usePermissions()

  const generatedAuthRoutes = generateLazyRoutes(authRoutesData, permissions)
  const generatedUnauthRoutes = generateLazyRoutes(
    unauthRoutesData,
    permissions,
  )

  if (auth.type === AuthType.NULL) return null

  if (auth.type === AuthType.UNAUTHENTICATED) {
    return (
      <Routes>
        {generatedUnauthRoutes}
        <Route
          path="*"
          element={<Navigate to={generatePath(routes.homePage)} />}
        />
      </Routes>
    )
  }

  return (
    <>
      <AppHeader />
      <Routes>
        {generatedAuthRoutes}
        <Route
          path="/"
          element={<Navigate to={generatePath(routes[auth.user.role])} />}
        />

        <Route
          path="/login"
          element={<Navigate to={generatePath(routes[auth.user.role])} />}
        />
      </Routes>
    </>
  )
}
export default App
