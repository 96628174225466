import { AuthType, useAuthContext } from 'providers/login-provider'
import { useMemo } from 'react'

const permissions = {
  canViewEmployee: 'can-view-employee',
  canViewFlats: 'can-view-flats',
  canViewOwnFlats: 'can-view-own-flats',
  canViewUserRegistration: 'can-view-user-registration',
  residentViewFlat: 'resident-view-flat',
  chairmanViewFlat: 'chairman-view-flat',
  chairmanMessage: 'chairman-message',
  canViewCosts: 'can-view-costs',
} as const

export type PermissionKey = keyof typeof permissions

export type Permissions = Record<PermissionKey, boolean>

export const usePermissions = () => {
  const { auth } = useAuthContext()

  const isAuthenticated =
    auth.type === AuthType.NULL || auth.type === AuthType.UNAUTHENTICATED

  return useMemo(
    () =>
      Object.entries(permissions).reduce(
        (result, [key, permissions]) => ({
          ...result,
          [key]: !isAuthenticated
            ? auth.user.permissions
              ? auth.user.permissions.includes(permissions)
              : false
            : false,
        }),
        {} as Permissions,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth],
  )
}
