export const authRoutes = {
  super: '/super',
  employees: '/super/employees',
  employee: '/employee',
  employeeUserRegistration: '/employee/user/registration',
  flatProfile: '/employee/flatprofile/:flatId',
  flatEdit: '/employee/flatedit/:flatId',
  user: '/user',
  chairman: '/chairman',
  chairmanMsg: '/chairman/message',
  costs: '/employee/costs',
}

export const unauthRoutes = {
  login: '/login',
  registration: '/registration',
  homePage: '/',
}

export const routes = {
  ...authRoutes,
  ...unauthRoutes,
}
