import { request } from 'lib/request'

import { TAuth, TRefreshToken } from './login.codec'

export const refresh = async () =>
  request('/auth/refresh', 'POST')(TRefreshToken)

export type AuthInput = {
  login: string
  password: string
}

export const auth = ({ login, password }: AuthInput) =>
  request('/auth', 'POST', {
    username: login,
    password,
  })(TAuth)
