import { generateHeader } from 'cookies'

import { TRequestBody, TRequestMethod, TRequestType } from '.'

type RequestData = {
  reqMethod: TRequestMethod

  reqType?: TRequestType
  reqBody?: TRequestBody
}

export const useRequestData = ({
  reqBody,
  reqType = 'JSON',
  reqMethod,
}: RequestData) => {
  const requestMethod = reqMethod
  const requestHeaders = generateHeader(reqType)
  const requestBody = reqBody ? JSON.stringify(reqBody) : null

  return {
    body: requestBody,
    headers: requestHeaders,
    method: requestMethod,
  } as any
}
